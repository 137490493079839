import React, { Component, useContext, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "../../../../hooks/hook.axios";
import { formatMongoDBDate } from "../../../../Shared/paymentDetails/Details";
import { Link } from "react-router-dom";

// \\\\\\\\\\\-------This Component is modified for partner payment-----------///////

const OthersCashInCost = ({ date }) => {
  const searchRef = useRef();
  const [search, setSearch] = useState("");
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  let url = `paymentData?`;
  search && (url = url.concat(`s=${search}`));
  let nextDay = new Date(fromDate);
  nextDay = toDate ? toDate : nextDay.setDate(nextDay.getDate() + 1);
  fromDate && (url = url.concat(`&issue_date>=${fromDate}&issue_date<=${nextDay} `));
  const { data: paymentData = [] } = useQuery({
    queryKey: ["paymentData", search, fromDate, toDate],
    queryFn: async () => {
      const res = await axios.get(url);
      return res.data.data;
    },
  });

  const getUniqueNames = (data) => {
    const seen = new Set();
    return data.filter(item => {
        const k = item.name;
        return seen.has(k) ? false : seen.add(k);
    });
};
const uniqueData = getUniqueNames(paymentData);


  const handleSearch = () => {
    setSearch(searchRef.current.value);
  };
  const handleFormDate = () => {
    setFromDate(fromDateRef.current.value);
  };
  const handleToDate = () => {
    setToDate(toDateRef.current.value);
  };

  
  return (
    <div>
      {/* <input ref={searchRef} className="input-sm bg-gray-300" type="text" />{" "}
      <button className="bg-red-300 p-1" onClick={handleSearch}>
        Search
      </button> */}
     
      <div>
        <h3 className="text-3xl">Partner Payment</h3>
        <div className="overflow-x-auto">
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Name</th>
               

                <th></th>
              </tr>
            </thead>
            <tbody>
              {uniqueData?.slice().reverse().map((pData, i) => (
                <tr key={i}>
                  <th>{i + 1}</th>
                  <td>{pData.name}</td>
                  <td>
                    <Link
                      to={`/dashboard/paymentDetails?url=paymentData?name=${pData.name}`}
                      className="btn btn-accent"
                    >
                      details
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OthersCashInCost;
