import React, { useRef, useState } from "react";
import axios from "../../hooks/hook.axios";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { useReactToPrint } from 'react-to-print';



export const formatMongoDBDate = (mongodbDateStr) => {
  const date = new Date(mongodbDateStr);
  const options = { day: "2-digit", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", options);
  return formattedDate;
};




function Details() {
  const [searchParams] = useSearchParams();
  let url = searchParams.get("url");
  const splitUrl = url.split('=')
  const name = splitUrl[1]
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  let nextDay = new Date(fromDate);
  nextDay = toDate ? toDate : nextDay.setDate(nextDay.getDate() + 1);
  fromDate && (url = url.concat(`&issue_date>=${fromDate}&issue_date<=${nextDay} `));
  const { data = [] } = useQuery({
    queryKey: ["details", url, fromDate, toDate],
    queryFn: async () => {
      const res = await axios.get(url);
      return res.data.data;
    },
  });







  const handleFormDate = () => {
    setFromDate(fromDateRef.current.value);
  };
  const handleToDate = () => {
    setToDate(toDateRef.current.value);
  };

  function sumNumericFields(array) {
    const totals = {};
    array.forEach((obj) => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (typeof value === "number") {
            if (!totals.hasOwnProperty(key)) {
              totals[key] = 0;
            }
            totals[key] += value;
          }
        }
      }
    });

    return totals;
  }
  const total = data ? sumNumericFields(data) : {};

  

  return (
    <div className="m-6">

      <div className="flex justify-between">
       <div>
       <input ref={fromDateRef} onChange={handleFormDate} className="input-sm bg-gray-300 " type="date" />{" "}
        <button className="bg-red-300 p-1 bg:m-1 " onClick={handleFormDate}>
          Search
        </button>
        
        <input ref={toDateRef} onChange={handleToDate} className="input-sm bg-gray-300 " type="date" />{" "}
        <button className="bg-red-300 p-1" onClick={handleToDate}>
          Search
        </button>
       </div>

        <button className="btn" onClick={() => {
          handlePrint(null, () => contentToPrint.current);
        }}>PRINT
        </button>
      </div>

      <div ref={contentToPrint}>

        <h3 className="text-5xl text-center mb-5 mt-5">Statement of {name}</h3>

        <div className="overflow-x-auto">
          <table className="table">
            {/* head */}
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Date</th>
                <th>Quantity</th>
                <th>Size</th>
                <th>Weight/KG</th>
                <th>Price/KG</th>
                <th>Total Price</th>
                <th>Paid Amount</th>
                <th>Due</th>
                <th>Discount Amount</th>
                <th>Note</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.slice().reverse().map((aData, i) => (
                <tr key={i}>
                  <th></th>
                  <td>{aData.name}</td>
                  <td>{formatMongoDBDate(aData.issue_date)}</td>
                  <td>{aData.qunatity}</td>
                  <td>{aData.size}</td>
                  <td>{aData.weight}</td>
                  <td>{aData.price}</td>
                  <td>{aData.total_price}</td>
                  <td>{aData.paid_amount}</td>
                  <td>{aData?.due_amount}</td>
                  <td>{aData.discount_amount}</td>
                  <td>{aData.note}</td>
                  <td>{aData.issued_by}</td>
                </tr>
              ))}
              <tr>
                <th></th>
                <th>Total</th>
                {/* <th></th> */}
                <th>{total.qunatity}</th>
                <th></th>
                <th>{total.weight}</th>
                <th></th>
                <th>{total.total_price}</th>
                <th>{total.paid_amount}</th>
                <th>{total.due_amount}</th>
                <th>{total.discount_amount}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Details;
