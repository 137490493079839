import React, { useContext, useRef, useState } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../../../Context/AuthProvider";
import axios from "../../../hooks/hook.axios";
import { Link } from "react-router-dom";
import { formatMongoDBDate } from "../../../Shared/paymentDetails/Details";
import DashboardNav from "../../../Layout/DashboardNav/DashboardNav";

const SalaryHistory = () => {

    const searchRef = useRef();
    const [search, setSearch] = useState("");
    const fromDateRef = useRef();
    const toDateRef = useRef();
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    let url = `salaryCollections?`;
  
    const { data: saalaryData = [] } = useQuery({
        queryKey: ["saalaryData", search],
        queryFn: async () => {
            const res = await axios.get(url);
            return res.data.data;
        },
    });



    const getUniqueNames = (data) => {
        const seen = new Set();
        return data.filter(item => {
            const k = item.issue_date;
            // console.log(k)
            return seen.has(k) ? false : seen.add(k);
        });
    };
    const uniqueData = getUniqueNames(saalaryData);




    const handleSearch = () => {
        setSearch(searchRef.current.value);
    };
    const handleFormDate = () => {
        setFromDate(fromDateRef.current.value);
    };
    const handleToDate = () => {
        setToDate(toDateRef.current.value);
    };

    return (
        <div>
            
            <div>
                {/* <DashboardNav></DashboardNav> */}
                <div className="overflow-x-auto">
                    <table className="table">
                        {/* head */}
                        <thead>
                            <tr>
                                <th></th>
                                <th>Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {uniqueData?.slice().reverse().map((data, i) => (
                                <tr>
                                    <th>{i + 1}</th>
                                    <td>{formatMongoDBDate(data.issue_date)}</td>
                                    {/* <td>{data.name}</td> */}
                                    <td>Salary</td>
                                    <td>
                                        <Link to={`/dashboard/salaryDetails?url=salaryCollections?issue_date=${formatMongoDBDate(data.issue_date)}`} className="btn btn-accent">
                                            Details
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SalaryHistory;