import React, {  useRef, useState } from "react";
import axios from "../../../../hooks/hook.axios";

import { useQuery } from "react-query";
import { Link } from "react-router-dom";

const FurnaceData = () => {
  const searchRef = useRef();
  const [search, setSearch] = useState("");
 

  let url = `furnaceData?`;
  search && (url = url.concat(`s=${search}`));
 
  const { data: furnaceData = [] } = useQuery({
    queryKey: ["furnaceData", search],
    queryFn: async () => {
      const res = await axios.get(url);
      return res.data.data;
    },
  });



  const getUniqueNames = (data) => {
    const seen = new Set();
    return data.filter(item => {
        const k = item.name;
        return seen.has(k) ? false : seen.add(k);
    });
};

    const uniqueData = getUniqueNames(furnaceData);

  
  


  const handleSearch = () => {
    setSearch(searchRef.current.value);
  };
 

  return (
    <div>
      <input ref={searchRef} className="input-sm bg-gray-300" type="text" />{" "}
      <button className="bg-red-300 p-1" onClick={handleSearch}>
        Search
      </button>
    
      <div>
        <h3 className="text-3xl">Furnace Data</h3>
        <div className="overflow-x-auto">
          <table className="table">
            {/* head */}
            <thead>
              <tr>
                <th></th>
                <th>Agent Name</th>
              

                <th></th>
              </tr>
            </thead>
            <tbody>
              {uniqueData?.slice().reverse().map((data, i) => (
                <tr key={i}>
                  <th>{i + 1}</th>
                  <td>{data.name}</td>
                 
                  <td>
                    <Link to={`/dashboard/details?url=furnaceData?name=${data.name}`} className="btn btn-accent">
                      details
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FurnaceData;
