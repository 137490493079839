import React from 'react';
import { Link } from 'react-router-dom';
// import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const DashboardNav = () => {
    return (
        <div>
            {/* <header className="p-4 dark:bg-gray-100 dark:text-gray-800">
                <div className="container flex justify-between h-16 mx-auto md:justify-center md:space-x-8">
                    <ul className="items-stretch hidden space-x-3 md:flex">
                        <li className="flex">
                            <Link to='/dashboard/salaryHistory' rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1 border-b-2 dark:border-">Salary</Link>
                        </li>
                        <li className="flex">
                            <Link to='/dashboard/partnerPaymentHistory' rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1 border-b-2 dark:border-">Partners Payment</Link>
                        </li>
                        <li className="flex">
                            <Link to='/dashboard/woodPowderHistory' rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1 border-b-2 dark:border- dark:text-violet-600 dark:border-violet-600">Wood Powder</Link>
                        </li>
                    </ul>

                    <br></br>

                    <ul className="items-stretch hidden space-x-3 md:flex">
                        <li className="flex">
                            <Link to='/dashboard/wireDataHistory' rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1 border-b-2 dark:border-">Wire Data</Link>
                        </li>
                        <li className="flex">
                            <Link to='/dashboard/acidDataHistory' rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1 border-b-2 dark:border-">Acid Data</Link>
                        </li>
                        <li className="flex">
                            <Link to='/dashboard/historyOthersExpanses' rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1 border-b-2 dark:border-">Others Expanses</Link>
                        </li>
                    </ul>

                </div>
            </header> */}
            <div className="navbar bg-base-100">
               
                <div className="flex-none">
                    <ul className="menu menu-horizontal px-1">
                        <li>
                            <details>
                                <summary>Debit Data</summary>
                                <ul className="bg-base-100 rounded-t-none p-2">
                                    <li><Link to='/dashboard/salaryHistory'>Salary</Link></li>
                                    <li><Link to='/dashboard/partnerPaymentHistory'>Partner Payment</Link></li>
                                    <li><Link to='/dashboard/woodPowderHistory'>Woood Powder</Link></li>
                                    <li><Link to='wireDataHistory'>Wire Data</Link></li>
                                    <li><Link to='/dashboard/acidDataHistory'>Acid Data</Link></li>
                                    <li><Link to='/dashboard/historyOthersExpanses'>Others Expanses</Link></li>
                                  
                                </ul>
                            </details>
                        </li>
                        <li>
                            <details>
                                <summary>Credit Data</summary>
                                <ul className="bg-base-100 rounded-t-none p-2">
                                <li><Link to='/dashboard/historyFurnace'>Furnace</Link></li>
                                <li><Link to='/dashboard/historyMsWire'>MS Wire</Link></li>
                                <li><Link to='/dashboard/historyGi'>GI Wire</Link></li>
                                <li><Link to='/dashboard/historyInvest'>Invest Data</Link></li>
                                </ul>
                            </details>
                        </li>
                    </ul>
                </div>
            </div>
          
        </div>
    );
};

export default DashboardNav;