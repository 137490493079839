import React, { useContext, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "../../../hooks/hook.axios";
import { Link } from "react-router-dom";
import { formatMongoDBDate } from "../../../Shared/paymentDetails/Details";
import DashboardNav from "../../../Layout/DashboardNav/DashboardNav";

const HistoryOthersExpanses = () => {

    const searchRef = useRef();
    const [search, setSearch] = useState("");
    const fromDateRef = useRef();
    const toDateRef = useRef();
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    let url = `otherExpanses?`;
    search && (url = url.concat(`s=${search}`));
    let nextDay = new Date(fromDate);
    nextDay = toDate ? toDate : nextDay.setDate(nextDay.getDate() + 1);
    fromDate && (url = url.concat(`&issue_date>=${fromDate}&issue_date<=${nextDay} `));
    const { data: otherExpanses = [] } = useQuery({
        queryKey: ["otherExpanses", search, fromDate, toDate],
        queryFn: async () => {
            const res = await axios.get(url);
            return res.data.data;
        },
    });


    const getUniqueNames = (data) => {
        const seen = new Set();
        return data.filter(item => {
            const k = item.issue_date;
            return seen.has(k) ? false : seen.add(k);
        });
    };

    const uniqueData = getUniqueNames(otherExpanses);

    console.log(otherExpanses);
    const handleSearch = () => {
        setSearch(searchRef.current.value);
    };
    const handleFormDate = () => {
        setFromDate(fromDateRef.current.value);
    };
    const handleToDate = () => {
        setToDate(toDateRef.current.value);
    };

    return (
        <div>
            {/* <DashboardNav></DashboardNav> */}
            {/* <input ref={searchRef} className="input-sm bg-gray-300" type="text" />{" "}
            <button className="bg-red-300 p-1" onClick={handleSearch}>
                Search
            </button> */}
            {/* <input ref={fromDateRef} onChange={handleFormDate} className="input-sm bg-gray-300 ml-20" type="date" />{" "}
        <button className="bg-red-300 p-1" onClick={handleFormDate}>
          Search
        </button>
        <input ref={toDateRef} onChange={handleToDate} className="input-sm bg-gray-300 ml-20" type="date" />{" "}
        <button className="bg-red-300 p-1" onClick={handleToDate}>
          Search
        </button> */}
            <div>
                <div className="overflow-x-auto">
                    <table className="table">
                        {/* head */}
                        <thead>
                            <tr>
                                <th></th>
                                <th>Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {uniqueData?.slice().reverse().map((data, i) => (
                                <tr>
                                    <th>{i + 1}</th>
                                    <td>{formatMongoDBDate(data.issue_date)}</td>
                                    <td>Expanses</td>
                                    <td>
                                        <Link to={`/dashboard/otherExpansesDetails?url=otherExpanses?issue_date=${formatMongoDBDate(data.issue_date)}`} className="btn btn-accent">
                                            details
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default HistoryOthersExpanses;