import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Context/AuthProvider";
import logo from "../../assets/logo.jpeg";
const Navbar = () => {
  const { user, logOut, setUser, Dashboard } = useContext(AuthContext);
  const handleLogOut = () => {
    logOut().then(() => {
      setUser(null);
    });
  };

  return (
    <div className="navbar bg-black-500">
      <div className="navbar-start">
        <div className="dropdown bg-black-500">
          <label tabIndex={1} role="button" className="btn btn-ghost lg:hidden ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
            </svg>
          </label>
          <ul tabIndex={0} className="menu menu-sm dropdown-content mt-2 z-[1] p-2 shadow text-red-500 rounded-box w-52">


            {user?.uid ? (
              <>
                <li>
                  {" "}
                  <li>
                    <p className="">Stock : {Dashboard.data?.wire_stock}kg</p>
                  </li>
                  <li>
                    <Link to="/cashOut">Debit</Link>
                  </li>
                  <li>
                    <Link to="/cashIn">Credit</Link>
                  </li>
                  <li>
                    <Link to="/fireCount">Fire Count</Link>
                  </li>
                  <li>
                    <Link to="/addusers">Add Users</Link>
                  </li>
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li>
                  <Link to="/loggedInUsers">Admins & Users</Link>
                </li>
                <li>
                  <Link className="font-bold"> {user?.displayName}</Link>{" "}
                </li>
                <div className="avatar"></div>
                <li>
                  <Link className="" onClick={logOut}>
                    Logout
                  </Link>
                </li>
              </>
            ) : (
              <li className="">
                <Link to="/login">Login</Link>
              </li>
            )}
          </ul>
        </div>
        <Link to="/" className="btn btn-ghost text-xl">
          <img className="w-9 rounded" src={logo} alt="Italian Trulli"></img>
        </Link>
        {user && (
          <div className="grid">
            <p className="btn btn-ghost text-xl">Balance : {Dashboard.data?.current_balance.toFixed(2)}</p>
          </div>
        )}
      </div>
      <div className="navbar-center hidden lg:flex ">
        <ul className="menu menu-horizontal px-1">
          {user?.uid ? (
            <>
              <li>
                <p className="">Stock : {Dashboard.data?.wire_stock}kg</p>
              </li>
              <li>
                <Link to="/cashOut">Debit</Link>
              </li>
              <li>
                <Link to="/cashIn">Credit</Link>
              </li>
              <li>
                <Link to="/fireCount">Fire Count</Link>
              </li>
              <li>
                {" "}
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li>
                <Link to="/loggedInUsers">Admins & Users</Link>
              </li>
              <li>
                <Link to="/addusers">Add User</Link>
              </li>

              <li>
                <Link className="font-bold"> {user?.displayName}</Link>{" "}
              </li>
              <div className="avatar"></div>
              <li>
                <Link className="" onClick={handleLogOut}>
                  Logout
                </Link>
              </li>
            </>
          ) : (
            <li>
              <Link to="/login">Login</Link>
            </li>
          )}
        </ul>
      </div>
      <label htmlFor="dashboard-drawer" tabIndex={2} role="button" className="btn btn-ghost lg:hidden ms-20">
        Open Sidebar
      </label>
    </div>
  );
};

export default Navbar;
