import { createBrowserRouter } from "react-router-dom";
import Main from "../../Layout/Main";
import Home from "../../Home/Home/Home";
import CashIn from "../../Pages/CashIn/CashIn";
import CashOut from "../../Pages/CashOut/CashOut";
import Login from "../../Pages/Login/Login";
import SignUp from "../../Pages/Signup/SignUp";
import AddUsers from "../../Pages/AddUsers/AddUsers";
import DashboardLayout from "../../Layout/DashboardLayout";
import SalaryData from "../../Pages/Dashboard/SalaryData/SalaryData";
import WoodPowderData from "../../Pages/Dashboard/WoodPowderData/WoodPowderData";
import WireData from "../../Pages/Dashboard/WireData/WireData";
import AcidData from "../../Pages/Dashboard/AcidData/AcidData";
import OthersExpanse from "../../Pages/Dashboard/OthersExpanse/OthersExpanse";
import FurnaceData from "../../Pages/Dashboard/CashINData/FurnaceData/FurnaceData";
import OthersCashInCost from "../../Pages/Dashboard/CashINData/OthersCashInCost/OthersCashInCost";
import UserDetails from "../../Pages/Dashboard/UserDetails/UserDetails";
import MwireData from "../../Pages/Dashboard/CashINData/MswiresData/MwireData";
import GIwireData from "../../Pages/Dashboard/GIwireData/GIwireData";
import InvestData from "../../Pages/Dashboard/InvestData/InvestData";
import AssetsData from "../../Pages/Dashboard/AssetsData/AssetsData";
import LoggedInUsers from "../../Pages/LoggedInUsers/LoggedInUsers";
import PrivetAdmin from "../privet/privet";
import PrivetAcounts from "../privet/acount";
import Details from "../../Shared/paymentDetails/Details";
import Stock from "../../Pages/CashIn/Stock/Stock";
import FireCount from "../../Pages/FireCount/FireCount";
import FireData from "../../Pages/Dashboard/FireData/FireData";
import PaymentDetails from "../../Shared/paymentDetails/PaymentDetails";
import RepairsCost from "../../Pages/Dashboard/FireRepairs/RepairsCost";
import OtherExpansesDetails from "../../Shared/paymentDetails/OtherExpansesDetails/OtherExpansesDetails";
import DashboardHome from "../../Pages/Dashboard/DashBoradHome/DashboardHome";
import SalaryDetails from "../../Shared/paymentDetails/SalaryDetails";
import SalaryHistory from "../../Pages/History/SalaryHistory/SalaryHistory";
import HistoryPartnerPayyment from "../../Pages/History/HistoryPartnerPayment/HistoryPartnerPayyment";
import HistoryWoodPowderData from "../../Pages/History/HistoryWoodPowderData/HistoryWoodPowderData";
import HistoryWireData from "../../Pages/History/HistoryWireData/HistoryWireData";
import HistoryAcidData from "../../Pages/History/HistoryAcidData/HistoryAcidData";
import HistoryOthersExpanses from "../../Pages/History/HistoryOthersExpanses/HistoryOthersExpanses";
import HistoryFurnaceData from "../../Pages/History/HistoryofFurnace/HistoryFurnaceData";
import HistoryMsWire from "../../Pages/History/HistoryMsWire/HistoryMsWire";
import HistoryGiData from "../../Pages/History/HistoryOfGiData/HistoryGiData";
import HistoryInvestData from "../../Pages/History/HistoryInvestData/HistoryInvestData";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      {
        path: "/cashIn",
        element: (
          <PrivetAcounts>
            <CashIn />
          </PrivetAcounts>
        ),
      },
      {
        path: "/cashOut",
        element: (
          <PrivetAcounts>
            <CashOut />
          </PrivetAcounts>
        ),
      },
      {
        path: "/fireCount",
        element: (
          <PrivetAcounts>
            <FireCount />
          </PrivetAcounts>
        ),
      },
      {
        path: "/login",
        element: <Login></Login>,
      },
      {
        path: "/signup",
        element: <SignUp></SignUp>,
      },
      {
        path: "/addusers",
        element: (
          <PrivetAdmin>
            <AddUsers />
          </PrivetAdmin>
        ),
      },
      {
        path: "/loggedInUsers",
        element: (
          <PrivetAdmin>
            <LoggedInUsers />
          </PrivetAdmin>
        ),
      },
      {
        path: "/dashboard/details",
        element: <Details></Details>,
      },
      {
        path: "/dashboard/paymentDetails",
        element: <PaymentDetails></PaymentDetails>,
      },
      {
        path: "/dashboard/otherExpansesDetails",
        element: <OtherExpansesDetails></OtherExpansesDetails>
      },
      {
        path: "/dashboard/salaryDetails",
        element: <SalaryDetails></SalaryDetails>
      },
    ],
  },
  {
    path: "/dashboard",
    element: <DashboardLayout></DashboardLayout>,
    children: [
      {
        path: "/dashboard",
        element: <DashboardHome></DashboardHome>,
      },
      {
        path: "/dashboard/salaryData",
        element: <SalaryData></SalaryData>,
      },
   
      {
        path: "/dashboard/woodPowederData",
        element: <WoodPowderData></WoodPowderData>,
      },
      {
        path: "/dashboard/wireData",
        element: <WireData></WireData>,
      },
      {
        path: "/dashboard/AcidData",
        element: <AcidData></AcidData>,
      },
      {
        path: "/dashboard/otherExpanses",
        element: <OthersExpanse></OthersExpanse>,
      },
      {
        path: "/dashboard/furnace",
        element: <FurnaceData></FurnaceData>,
      },
      {
        path: "/dashboard/othersData",
        element: <OthersCashInCost></OthersCashInCost>,
      },
      {
        path: "/dashboard/userDetailsData",
        element: <UserDetails></UserDetails>,
      },
      {
        path: "/dashboard/mwiresData",
        element: <MwireData></MwireData>,
      },
      {
        path: "/dashboard/gIWires",
        element: <GIwireData></GIwireData>,
      },
      {
        path: "/dashboard/investData",
        element: <InvestData></InvestData>,
      },
      {
        path: "/dashboard/assetsData",
        element: <AssetsData></AssetsData>,
      },
      {
        path: "/dashboard/stock",
        element: <Stock></Stock>,
      },
      {
        path: "/dashboard/fireData",
        element: <FireData></FireData>,
      },
      {
        path: "/dashboard/fireRepairs",
        element:<RepairsCost></RepairsCost>,
      },

      // ---------History----------

      {
        path: "/dashboard/home",
        element:<SalaryHistory></SalaryHistory>
      },
      {
        path: "/dashboard/salaryHistory",
        element:<SalaryHistory></SalaryHistory>
      },
      {
        path: "/dashboard/partnerPaymentHistory",
        element:<HistoryPartnerPayyment></HistoryPartnerPayyment>
      },
      {
        path: "/dashboard/woodPowderHistory",
        element:<HistoryWoodPowderData></HistoryWoodPowderData>
      },
      {
        path: "/dashboard/wireDataHistory",
        element:<HistoryWireData></HistoryWireData>
      },
      {
        path: "/dashboard/acidDataHistory",
        element:<HistoryAcidData></HistoryAcidData>
      },
      {
        path: "/dashboard/historyOthersExpanses",
        element:<HistoryOthersExpanses></HistoryOthersExpanses>
      },
      {
        path: "/dashboard/historyFurnace",
        element:<HistoryFurnaceData></HistoryFurnaceData>
      },
      {
        path: "/dashboard/historyMsWire",
        element:<HistoryMsWire></HistoryMsWire>
      },
      {
        path: "/dashboard/historyGi",
        element:<HistoryGiData></HistoryGiData>
      },
      {
        path: "/dashboard/historyInvest",
        element:<HistoryInvestData></HistoryInvestData>
      },
     
    ],
  },
]);

export default router;
