import React, {  useRef, useState } from "react";
import { formatMongoDBDate } from "../../../Shared/paymentDetails/Details";
import { useQuery } from "react-query";
import axios from "../../../hooks/hook.axios";
import { Link } from "react-router-dom";


const HistoryFurnaceData = () => {

    const searchRef = useRef();
    const [search, setSearch] = useState("");
   
  
    let url = `furnaceData?`;
    search && (url = url.concat(`s=${search}`));
   
    const { data: furnaceData = [] } = useQuery({
      queryKey: ["furnaceData", search],
      queryFn: async () => {
        const res = await axios.get(url);
        return res.data.data;
      },
    });
  
  
  
    const getUniqueNames = (data) => {
      const seen = new Set();
      return data.filter(item => {
          const k = item.issue_date;
          return seen.has(k) ? false : seen.add(k);
      });
  };
  
      const uniqueData = getUniqueNames(furnaceData);
  
    
    
  
  
    const handleSearch = () => {
      setSearch(searchRef.current.value);
    };

    return (
        <div>
      {/* <input ref={searchRef} className="input-sm bg-gray-300" type="text" />{" "}
      <button className="bg-red-300 p-1" onClick={handleSearch}>
        Search
      </button> */}
    
      <div>
        {/* <h3 className="text-3xl">Furnace Data</h3> */}
        <div className="overflow-x-auto">
          <table className="table">
            {/* head */}
            <thead>
              <tr>
                <th></th>
                <th>Date</th>
              

                <th></th>
              </tr>
            </thead>
            <tbody>
              {uniqueData?.slice().reverse().map((data, i) => (
                <tr key={i}>
                  <th>{i + 1}</th>
                  <td>{formatMongoDBDate(data.issue_date)}</td>
                  <td>Furnace</td>
                 
                  <td>
                    <Link to={`/dashboard/details?url=furnaceData?issue_date=${formatMongoDBDate(data.issue_date)}`} className="btn btn-accent">
                      details
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    );
};

export default HistoryFurnaceData;