import React from 'react';
import SalaryHistory from '../../History/SalaryHistory/SalaryHistory';
import { Link } from 'react-router-dom';
import DashboardNav from '../../../Layout/DashboardNav/DashboardNav';

const DashboardHome = () => {
    return (
        <div>
            <DashboardNav></DashboardNav>
        </div>
    );
};

export default DashboardHome;